import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './components/ui/tabs';
import { Card, CardContent } from "./components/ui/card";
import { ScrollArea } from "./components/ui/scroll-area";
import { Button } from "./components/ui/button";
import SearchBar from './components/SearchBar';
import { useTheme } from "next-themes";
import { MoonIcon, SunIcon } from "lucide-react";
import ArtistTab from './components/ArtistTab';
import SummaryTab from './components/SummaryTab';
import { useCatalog } from './hooks/useCatalog';
import { useSearch } from './hooks/useSearch';

const ArtistCatalog = () => {
  const { 
    catalog, 
    setCatalog, 
    isLoading, 
    editMode, 
    setEditMode, 
    saveChanges, 
    downloadArtistCatalog, 
    handleAddTrack 
  } = useCatalog();
  const { searchTerm, setSearchTerm, filteredCatalog, setFilteredCatalog, handleSearch, sortByReleaseDate, sortOrder } = useSearch(catalog);
  const [selectedArtist, setSelectedArtist] = useState('summary');
  const [isAddingTrack, setIsAddingTrack] = useState(false);
  const { theme, setTheme } = useTheme();
  
  if (isLoading) return <div>Loading...</div>;

  const handleEdit = (artist, index, field, value) => {
    const updatedCatalog = { ...catalog };
    const updatedFilteredCatalog = { ...filteredCatalog };
    const fields = field.split('.');

    // Update original catalog
    let currentOriginal = updatedCatalog[artist][index];
    for (let i = 0; i < fields.length - 1; i++) {
      currentOriginal = currentOriginal[fields[i]];
    }
    currentOriginal[fields[fields.length - 1]] = value;

    // Update filtered catalog
    if (updatedFilteredCatalog[artist] && updatedFilteredCatalog[artist][index]) {
      let currentFiltered = updatedFilteredCatalog[artist][index];
      for (let i = 0; i < fields.length - 1; i++) {
        currentFiltered = currentFiltered[fields[i]];
      }
      currentFiltered[fields[fields.length - 1]] = value;
    }

    setCatalog(updatedCatalog);
    setFilteredCatalog(updatedFilteredCatalog);
  };

  const handleAddNewTrack = () => {
    if (selectedArtist !== 'summary') {
      setIsAddingTrack(true);
    } else {
      alert('Please select an artist before adding a new track.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-background text-foreground">
      <header className="sticky top-0 z-40 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="container flex flex-col sm:flex-row items-center justify-between py-4 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-4 sm:mb-0">
            <img src="https://static.wixstatic.com/media/ebd611_3711b599d89649f093bddf390131d121~mv2.png" alt="Logo" className="h-8 w-auto mr-4" />
            <h1 className="text-xl font-bold">Artist Catalog</h1>
          </div>
          <div className="flex items-center space-x-4">
            <SearchBar
              searchTerm={searchTerm}
              handleSearch={handleSearch}
              sortByReleaseDate={sortByReleaseDate}
              sortOrder={sortOrder}
              editMode={editMode}
              setEditMode={setEditMode}
              saveChanges={saveChanges}
              downloadArtistCatalog={downloadArtistCatalog}
              selectedArtist={selectedArtist}
              onAddNewTrack={handleAddNewTrack}
            />
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
            >
              {theme === "dark" ? <SunIcon className="h-5 w-5" /> : <MoonIcon className="h-5 w-5" />}
              <span className="sr-only">Toggle theme</span>
            </Button>
          </div>
        </div>
      </header>
      <main className="flex-1 overflow-hidden">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <Tabs defaultValue="summary" className="space-y-6">
            <div className="flex items-center justify-between">
              <TabsList className="overflow-x-auto">
                <TabsTrigger value="summary" onClick={() => setSelectedArtist('summary')}>
                  Summary
                </TabsTrigger>
                {Object.keys(catalog).map((artist) => (
                  <TabsTrigger key={artist} value={artist} onClick={() => setSelectedArtist(artist)}>
                    {artist}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
            <Card>
              <CardContent className="p-6">
                <ScrollArea className="h-[calc(100vh-12rem)]">
                  <TabsContent value="summary">
                    <SummaryTab catalog={filteredCatalog} />
                  </TabsContent>
                  {Object.keys(filteredCatalog).map((artist) => (
                    <TabsContent key={artist} value={artist}>
                      <ArtistTab
                        artist={artist}
                        songs={filteredCatalog[artist]}
                        editMode={editMode}
                        handleEdit={handleEdit}
                        handleAddTrack={(newTrack) => {
                          handleAddTrack(artist, newTrack);
                          setIsAddingTrack(false);
                          handleSearch(searchTerm); // Re-apply the search after adding a new track
                        }}
                        isAddingTrack={isAddingTrack}
                        setIsAddingTrack={setIsAddingTrack}
                        selectedArtist={selectedArtist}
                      />
                    </TabsContent>
                  ))}
                </ScrollArea>
              </CardContent>
            </Card>
          </Tabs>
        </div>
      </main>
    </div>
  );
};

export default ArtistCatalog;