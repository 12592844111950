import { useState, useEffect } from 'react';

export const useSearch = (initialCatalog) => {
  const [catalog, setCatalog] = useState(initialCatalog);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCatalog, setFilteredCatalog] = useState(catalog);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedCatalog, setSortedCatalog] = useState(null);

  useEffect(() => {
    setFilteredCatalog(catalog);
    setSortedCatalog(null);
  }, [catalog]);

  const searchCatalog = (term) => {
    const catalogToSearch = sortedCatalog || catalog;
    if (term === '') {
      setFilteredCatalog(catalogToSearch);
      return;
    }

    const results = {};
    Object.entries(catalogToSearch).forEach(([artist, songs]) => {
      const filteredSongs = songs.filter(song => 
        song.songDetails.title.toLowerCase().includes(term.toLowerCase()) ||
        (Array.isArray(song.songDetails.artist) 
          ? song.songDetails.artist.some(a => a.toLowerCase().includes(term.toLowerCase()))
          : song.songDetails.artist.toLowerCase().includes(term.toLowerCase())) ||
        song.songDetails.releaseDate.includes(term)
      );
      if (filteredSongs.length > 0) {
        results[artist] = filteredSongs;
      }
    });
    setFilteredCatalog(Object.keys(results).length > 0 ? results : { 'No Results': [] });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    searchCatalog(term);
  };

  const sortByReleaseDate = () => {
    const catalogToSort = sortedCatalog || filteredCatalog;
    const sorted = {};
    Object.entries(catalogToSort).forEach(([artist, songs]) => {
      sorted[artist] = [...songs].sort((a, b) => {
        const dateA = new Date(a.songDetails.releaseDate || '9999-12-31');
        const dateB = new Date(b.songDetails.releaseDate || '9999-12-31');
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
    });
    setFilteredCatalog(sorted);
    setSortedCatalog(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return { searchTerm, setSearchTerm, catalog, setCatalog, filteredCatalog, setFilteredCatalog, handleSearch, sortByReleaseDate, sortOrder };
};