import { useState } from 'react';
import { catalogData } from '../data/main-catalog';
import { normalizeDate } from '../utils/dateUtils';

export const useCatalog = () => {
  const [catalog, setCatalog] = useState(() => {
    // Normalize dates when initializing the catalog
    const normalizedCatalog = {};
    Object.entries(catalogData).forEach(([artist, songs]) => {
      normalizedCatalog[artist] = songs.map(song => ({
        ...song,
        songDetails: {
          ...song.songDetails,
          releaseDate: normalizeDate(song.songDetails.releaseDate)
        }
      }));
    });
    return normalizedCatalog;
  });

  const [editMode, setEditMode] = useState(false);

  const saveChanges = () => {
    // This function no longer needs to interact with localStorage
    setEditMode(false);
  };

  const downloadArtistCatalog = (selectedArtist) => {
    const artistData = JSON.stringify(catalog[selectedArtist], null, 2);
    const blob = new Blob([artistData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedArtist}-catalog.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleAddTrack = (artist, newTrack) => {
    setCatalog(prevCatalog => ({
      ...prevCatalog,
      [artist]: [...prevCatalog[artist], newTrack]
    }));
  };

  return { 
    catalog, 
    setCatalog,
    editMode, 
    setEditMode, 
    saveChanges, 
    downloadArtistCatalog, 
    handleAddTrack
  };
};