import React from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { SortAsc, SortDesc, Edit, Save, Download, X, Plus } from 'lucide-react';

const SearchBar = ({
  searchTerm,
  handleSearch,
  sortByReleaseDate,
  sortOrder,
  editMode,
  setEditMode,
  saveChanges,
  downloadArtistCatalog,
  selectedArtist,
  onAddNewTrack
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Input
        placeholder="Search songs..."
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        className="w-[150px] lg:w-[250px]"
      />
      <Button variant="outline" size="icon" onClick={() => handleSearch('')}>
        <X className="h-4 w-4" />
      </Button>
      <Button variant="outline" size="sm" onClick={sortByReleaseDate}>
        {sortOrder === 'asc' ? <SortAsc className="mr-2 h-4 w-4" /> : <SortDesc className="mr-2 h-4 w-4" />}
        <span className="hidden sm:inline">Sort</span>
      </Button>
      {selectedArtist !== 'summary' && (
        <>
          <Button variant="outline" size="sm" onClick={() => setEditMode(!editMode)}>
            {editMode ? <X className="mr-2 h-4 w-4" /> : <Edit className="mr-2 h-4 w-4" />}
            <span className="hidden sm:inline">{editMode ? 'Cancel' : 'Edit'}</span>
          </Button>
          {editMode && (
            <Button variant="default" size="sm" onClick={saveChanges}>
              <Save className="mr-2 h-4 w-4" />
              <span className="hidden sm:inline">Save</span>
            </Button>
          )}
          <Button variant="outline" size="sm" onClick={onAddNewTrack}>
            <Plus className="mr-2 h-4 w-4" />
            <span className="hidden sm:inline">Add</span>
          </Button>
          <Button variant="secondary" size="sm" onClick={() => downloadArtistCatalog(selectedArtist)}>
            <Download className="mr-2 h-4 w-4" />
            <span className="hidden sm:inline">Download</span>
          </Button>
        </>
      )}
    </div>
  );
};

export default SearchBar;