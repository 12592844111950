// quai-catalog.js
export const quaiCatalog = [
    {
        "publishingDetails": {
          "no": 1,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "R Sterling",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "1' 31\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XW2387784",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/r-sterling/1697860983?i=1697860989"
      },
      {
        "publishingDetails": {
          "no": 2,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "ROTI N KEJU",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "2' 38\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6SA2307542",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/roti-n-keju/1697860983?i=1697861422"
      },
      {
        "publishingDetails": {
          "no": 3,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "TAK SURE",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "2' 16\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XW2325278",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/tak-sure/1697860983?i=1697861546"
      },
      {
        "publishingDetails": {
          "no": 4,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "TAJ MAHAL",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "2' 08\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6TI2311305",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/taj-mahal/1697860983?i=1697861550"
      },
      {
        "publishingDetails": {
          "no": 5,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "SHILKY",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "MR NOBODY",
          "artist": [
            "Quai",
            "Shilky"
          ],
          "language": "Malay",
          "duration": "2' 39\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XW2346610",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/mr-nobody-feat-shilky/1697860983?i=1697861807"
      },
      {
        "publishingDetails": {
          "no": 6,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "KELUAR DARI BLOK",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "2' 26\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6TI2398199",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/keluar-dari-blok/1697860983?i=1697861827"
      },
      {
        "publishingDetails": {
          "no": 7,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "ALPHA",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "2' 05\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6TI2381037",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/alpha/1697860983?i=1697861833"
      },
      {
        "publishingDetails": {
          "no": 8,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "MOHAMMAD AIMAN BIN AMINUDDIN",
              "percentage": "25.00%",
              "publisher": ""
            }
          ]
        },
        "songDetails": {
          "title": "OT",
          "artist": [
            "Quai",
            "Ichu"
          ],
          "language": "Malay",
          "duration": "2' 34\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6TI2339407",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/ot-feat-ichu/1697860983?i=1697862076"
      },
      {
        "publishingDetails": {
          "no": 9,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "4U",
          "artist": [
            "Quai"
          ],
          "language": "Malay",
          "duration": "2' 21\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XW2376530",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/4u/1697860983?i=1697862084"
      },
      {
        "publishingDetails": {
          "no": 10,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "NOBI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "SERASI",
          "artist": [
            "Quai",
            "Nobi"
          ],
          "language": "Malay",
          "duration": "2' 22\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6TI2381482",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/serasi-feat-nobi/1697860983?i=1697862088"
      },
      {
        "publishingDetails": {
          "no": 11,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "Izhar bin Ishak",
              "percentage": "25.00%",
              "publisher": "TAJA ARCHIVE"
            }
          ]
        },
        "songDetails": {
          "title": "RASA",
          "artist": [
            "Quai",
            "Izhar"
          ],
          "language": "Malay",
          "duration": "3' 12\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6QE2372755\n",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/rasa-feat-izhar/1697860983?i=1697862387"
      },
      {
        "publishingDetails": {
          "no": 12,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "Izhar bin Ishak",
              "percentage": "25.00%",
              "publisher": "TAJA ARCHIVE"
            }
          ]
        },
        "songDetails": {
          "title": "PUNCAK",
          "artist": [
            "Quai",
            "Izhar"
          ],
          "language": "Malay",
          "duration": "2' 57\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6TI2389460",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/puncak-feat-izhar/1697860983?i=1697862395"
      },
      {
        "publishingDetails": {
          "no": 13,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "GARD",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "PEKAN KE KOTA",
          "artist": [
            "Quai",
            "Gard"
          ],
          "language": "Malay",
          "duration": "3' 45\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XY2316222",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/pekan-ke-kota/1697860983?i=1697862399"
      },
      {
        "publishingDetails": {
          "no": 14,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "20.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "Izhar bin Ishak",
              "percentage": "20.00%",
              "publisher": "TAJA ARCHIVE"
            },
            {
              "role": "A",
              "songwriter": "NIK MUHAMMAD HAZIQ BIN AJ'MAN",
              "percentage": "10.00%",
              "publisher": ""
            }
          ]
        },
        "songDetails": {
          "title": "NO DELAY",
          "artist": [
            "Quai",
            "Izhar",
            "Nic"
          ],
          "language": "Malay",
          "duration": "3' 16\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XW2382721",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/no-delay-feat-izhar-nic/1697860983?i=1697862568"
      },
      {
        "publishingDetails": {
          "no": 15,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "C",
              "songwriter": "LASTKHALIF",
              "percentage": "25.00%",
              "publisher": ""
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "SYUKUR",
          "artist": [
            "Quai",
            "LastKhalif"
          ],
          "language": "Malay",
          "duration": "1' 56\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6SA2390506",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/syukur-feat-lastkhalif/1697860983?i=1697862574"
      },
      {
        "publishingDetails": {
          "no": 16,
          "roles": [
            {
              "role": "C",
              "songwriter": "QUAI",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "QUAI",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "JAYSTATION",
              "percentage": "25.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "Budak Pulau",
          "artist": [
            "Quai",
            "JAYSTATION"
          ],
          "language": "Malay/English",
          "duration": "3' 06\"",
          "releaseDate": "28.7.2023"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "SE6XW2355757",
          "upc": "",
          "repertoireOwner": "Ikhmal Danial Bin Helme Iskandar"
        },
        "link": "https://music.apple.com/my/album/budak-pulau/1697860983?i=1697862798"
      },
      // Add more songs for Quai
  ];