import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import SongDetails from './SongDetails';
import AddTrackForm from './AddTrackForm';

const ArtistTab = ({ 
  artist, 
  songs, 
  editMode, 
  handleEdit, 
  handleAddTrack, 
  isAddingTrack, 
  setIsAddingTrack, 
  selectedArtist 
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{artist} Catalog</CardTitle>
      </CardHeader>
      <CardContent>
        {isAddingTrack && selectedArtist === artist && (
          <AddTrackForm
            onAddTrack={(newTrack) => {
              handleAddTrack(newTrack);
              setIsAddingTrack(false);
            }}
            onCancel={() => setIsAddingTrack(false)}
            artist={artist}
          />
        )}
        {songs.length === 0 && !isAddingTrack ? (
          <Alert>
            <AlertTitle>No Results</AlertTitle>
            <AlertDescription>No songs found matching your search criteria.</AlertDescription>
          </Alert>
        ) : (
          <Accordion type="single" collapsible className="w-full">
            {songs.map((song, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger>{song.songDetails.title}</AccordionTrigger>
                <AccordionContent>
                  <SongDetails 
                    artist={artist} 
                    song={song} 
                    index={index} 
                    editMode={editMode} 
                    handleEdit={handleEdit} 
                  />
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </CardContent>
    </Card>
  );
};

export default ArtistTab;