// shilky-catalog.js
export const shilkyCatalog = [
  {
    "publishingDetails": {
      "no": 1,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "SunyiQ Sendiri",
      "artist": [
        "Shilky"
      ],
      "language": "Malay",
      "duration": "3'",
      "releaseDate": "2024-07-26"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "MYSZ3 24 10007",
      "upc": "",
      "repertoireOwner": "Muhammad Danish bin Fakrul Azmi "
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 2,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish Hakimi bin Joni Muhamat",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Beradd",
      "artist": [
        "Shilky",
        "Akkimwaru"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": " MYSZ3 24 10008",
      "upc": "",
      "repertoireOwner": "Muhammad Danish bin Fakrul Azmi "
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 3,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "pukul 10",
      "artist": [
        "Shilky"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "MYSZ3 24 10009\n",
      "upc": "",
      "repertoireOwner": "Muhammad Danish bin Fakrul Azmi "
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 4,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Ersan Henri Bin Azizi\n",
          "percentage": "25.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "50.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Larii",
      "artist": [
        "Shilky"
      ],
      "language": "Malay",
      "duration": "1' 52\"",
      "releaseDate": "2024-07-21"
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "MYSZ3 24 10010",
      "upc": "",
      "repertoireOwner": "Muhammad Danish bin Fakrul Azmi "
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 5,
      "roles": [
        {
          "role": "C",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "C",
          "songwriter": "Shamir Azlan Bin Md Kasan\n",
          "percentage": "25.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Muhammad Danish bin Fakrul Azmi ",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "En. Vibe",
      "artist": [
        "Shilky"
      ],
      "language": "Malay",
      "duration": "",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "AUDIO",
      "isrc": "MYSZ3 24 10011",
      "upc": "",
      "repertoireOwner": "Muhammad Danish bin Fakrul Azmi "
    },
    "link": ""
  }
    ];