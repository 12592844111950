import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { FileText, Music, Mic, Link as LinkIcon } from 'lucide-react';
import { Button } from "./ui/button";

const LabelValuePair = ({ label, value, editMode, onChange }) => (
  <p className="mb-1">
    <strong className="font-medium">{label}: </strong>
    {editMode ? (
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="border-b border-gray-300 focus:border-blue-500 outline-none"
      />
    ) : (
      <span>{value}</span>
    )}
  </p>
);

const SongDetails = ({ artist, song, index, editMode, handleEdit }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Card className="bg-slate-50 shadow-sm">
        <CardHeader className="pb-2">
          <CardTitle className="text-xl font-semibold flex items-center">
            <FileText className="mr-2 h-5 w-5 text-slate-500" />
            Publishing Details
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-0">
          {song.publishingDetails.roles.map((role, roleIndex) => (
            <div key={roleIndex} className="mb-4 last:mb-0">
              <LabelValuePair
                label="Role"
                value={role.role}
                editMode={editMode}
                onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.role`, value)}
              />
              <LabelValuePair
                label="Songwriter"
                value={role.songwriter}
                editMode={editMode}
                onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.songwriter`, value)}
              />
              <LabelValuePair
                label="Percentage"
                value={role.percentage}
                editMode={editMode}
                onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.percentage`, value)}
              />
              <LabelValuePair
                label="Publisher"
                value={role.publisher}
                editMode={editMode}
                onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.publisher`, value)}
              />
            </div>
          ))}
        </CardContent>
      </Card>

      <Card className="bg-slate-50 shadow-sm">
        <CardHeader className="pb-2">
          <CardTitle className="text-xl font-semibold flex items-center">
            <Music className="mr-2 h-5 w-5 text-slate-500" />
            Song Details
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-0">
          <LabelValuePair
            label="Title"
            value={song.songDetails.title}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'songDetails.title', value)}
          />
          <LabelValuePair
            label="Language"
            value={song.songDetails.language}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'songDetails.language', value)}
          />
          <LabelValuePair
            label="Duration"
            value={song.songDetails.duration}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'songDetails.duration', value)}
          />
          <LabelValuePair
            label="Release Date"
            value={song.songDetails.releaseDate}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'songDetails.releaseDate', value)}
          />
        </CardContent>
      </Card>

      <Card className="bg-slate-50 shadow-sm">
        <CardHeader className="pb-2">
          <CardTitle className="text-xl font-semibold flex items-center">
            <Mic className="mr-2 h-5 w-5 text-slate-500" />
            Master Recording Details
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-0">
          <LabelValuePair
            label="Version"
            value={song.masterRecordingDetails.version}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.version', value)}
          />
          <LabelValuePair
            label="ISRC"
            value={song.masterRecordingDetails.isrc}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.isrc', value)}
          />
          <LabelValuePair
            label="UPC"
            value={song.masterRecordingDetails.upc}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.upc', value)}
          />
          <LabelValuePair
            label="Repertoire Owner"
            value={song.masterRecordingDetails.repertoireOwner}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.repertoireOwner', value)}
          />
        </CardContent>
      </Card>

      <Card className="bg-slate-50 shadow-sm">
        <CardHeader className="pb-2">
          <CardTitle className="text-xl font-semibold flex items-center">
            <LinkIcon className="mr-2 h-5 w-5 text-slate-500" />
            Link
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-0">
          <LabelValuePair
            label="YouTube Link"
            value={song.link}
            editMode={editMode}
            onChange={(value) => handleEdit(artist, index, 'link', value)}
          />
          {!editMode && song.link && (
            <Button variant="outline" className="mt-2 text-blue-600 hover:text-blue-700" onClick={() => window.open(song.link, '_blank')}>
              View on YouTube
            </Button>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SongDetails;