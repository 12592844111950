// offgrid-catalog.js
export const offgridCatalog = [
    {
        "publishingDetails": {
          "no": 1,
          "roles": [
            {
              "role": "C",
              "songwriter": "Muhammad Danish bin Fakrul Azmi ",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            },
            {
              "role": "A",
              "songwriter": "Muhammad Danish bin Fakrul Azmi ",
              "percentage": "50.00%",
              "publisher": "0108 SLATAN SOLUTION"
            }
          ]
        },
        "songDetails": {
          "title": "SunyiQ Sendiri",
          "artist": [
            "Shilky"
          ],
          "language": "Malay",
          "duration": "3'",
          "releaseDate": "2024-07-26"
        },
        "masterRecordingDetails": {
          "version": "AUDIO",
          "isrc": "MYSZ3 24 10007",
          "upc": "",
          "repertoireOwner": "Muhammad Danish bin Fakrul Azmi "
        },
        "link": ""
      },
];