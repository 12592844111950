// maatjet-catalog.js
export const maatjetCatalog = [
  {
    "publishingDetails": {
      "no": 1,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "35.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Mohsein Kushairi",
          "percentage": "5.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Hakiim Tahir",
          "percentage": "10.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Angkasa",
      "artist": [
        "maatjet",
        "Offgrid",
        "Mohsein Kush"
      ],
      "language": "Malay",
      "duration": "3' 42\"",
      "releaseDate": "28 January 2022"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "FR-10S-22-09613",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": "https://www.youtube.com/watch?v=85wP5NKovhQ"
  },
  {
    "publishingDetails": {
      "no": 2,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "40.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Ikhmal Danial Bin Helme Iskandar",
          "percentage": "10.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "if tanya",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "3' 37\"",
      "releaseDate": "1 July 2022"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "FR-X20-22-67314",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": "https://www.youtube.com/watch?v=wGsUOumyB8w"
  },
  {
    "publishingDetails": {
      "no": 3,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "Lepas Show",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 07\"",
      "releaseDate": "3 Sept 2023"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "SE6SA2339111",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": "https://www.youtube.com/watch?v=diPQ3k1f0jk"
  },
  {
    "publishingDetails": {
      "no": 4,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "30.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Ainina Hasnul",
          "percentage": "5.00%",
          "publisher": ""
        },
        {
          "role": "A",
          "songwriter": "Shazrul Rejab",
          "percentage": "15.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Tolong Jadi Saksi",
      "artist": [
        "maatjet",
        "Gard",
        "Ainina Hasnul"
      ],
      "language": "Malay",
      "duration": "3' 51\"",
      "releaseDate": "10 October 2022"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "FR-X76-22-97524",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": "https://www.youtube.com/watch?v=rYs99sEtR74"
  },
  {
    "publishingDetails": {
      "no": 5,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "XTC",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 27\"",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310002",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": "https://www.youtube.com/watch?v=WUVRag0l7QI"
  },
  {
    "publishingDetails": {
      "no": 6,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Bukit Tunku",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 14\"",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310040",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 7,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "40.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izhar Ishak",
          "percentage": "10.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Tunggu",
      "artist": [
        "maatjet",
        "Izhar"
      ],
      "language": "Malay",
      "duration": "3' 35",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32311039",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 8,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Macam biasa",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 25\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310041",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 9,
      "roles": [
        {
          "role": "CA",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "75.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "C",
          "songwriter": "Ammar Asrar bin Mazlan",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Jalan Pulang",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "4' 06\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310042",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 10,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "30.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Ikhmal Danial Bin Helme Iskandar",
          "percentage": "10.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Zhe Kamil",
          "percentage": "10.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "Peluang",
      "artist": [
        "maatjet",
        "Quai",
        "Zhe Kamil",
        "cyndaquil"
      ],
      "language": "Malay",
      "duration": "3' 53\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310043",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 11,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Jika Takdir",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 49\"",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310044",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 12,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Kejar waktu interlude",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "1' 47\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310045",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 13,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "40.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Mohammad Aiman bin Aminuddin",
          "percentage": "10.00%",
          "publisher": ""
        }
      ]
    },
    "songDetails": {
      "title": "Aku mohon",
      "artist": [
        "maatjet",
        "Ichu"
      ],
      "language": "Malay",
      "duration": "2' 32\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310046",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 14,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "40.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izhar Ishak",
          "percentage": "10.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Siapa",
      "artist": [
        "maatjet",
        "Izhar"
      ],
      "language": "Malay",
      "duration": "3' 20\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310047",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 15,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Bukan Aku",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 13\"",
      "releaseDate": ""
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310048",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 16,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "25.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "C",
          "songwriter": "Baqhtear Rashid",
          "percentage": "25.00%",
          "publisher": "0108 SLATAN SOLUTION"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "25.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Baqhtear Rashid",
          "percentage": "12.50%",
          "publisher": "0108 SLATAN SOLTION"
        },
        {
          "role": "A",
          "songwriter": "Shazrul Rejab",
          "percentage": "12.50%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "Rumah Slatan",
      "artist": [
        "maatjet",
        "Gard",
        "Wuzgut"
      ],
      "language": "Malay",
      "duration": "2' 24\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310049",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 17,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        }
      ]
    },
    "songDetails": {
      "title": "Proton",
      "artist": [
        "maatjet"
      ],
      "language": "Malay",
      "duration": "2' 58\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "MYSZ32310050",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  {
    "publishingDetails": {
      "no": 18,
      "roles": [
        {
          "role": "C",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "50.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Izzad Daniell bin Sarip",
          "percentage": "40.00%",
          "publisher": "TAJA ARCHIVE"
        },
        {
          "role": "A",
          "songwriter": "Ikhmal Danial Bin Helme Iskandar",
          "percentage": "10.00%",
          "publisher": "0108 SLATAN SOLUTION"
        }
      ]
    },
    "songDetails": {
      "title": "WAYBACK",
      "artist": [
        "maatjet",
        "Quai"
      ],
      "language": "Malay",
      "duration": "3' 03\"",
      "releaseDate": "28 June 2024"
    },
    "masterRecordingDetails": {
      "version": "Audio",
      "isrc": "",
      "upc": "",
      "repertoireOwner": "Izzad Daniell Bin Sarip \n (990928-10-5439)"
    },
    "link": ""
  },
  // Add more songs for maatjet
  ];