// main-catalog.js
import { jaystationCatalog } from './jaystation-catalog.js';
import { maatjetCatalog } from './maatjet-catalog.js';
import { quaiCatalog } from './quai-catalog.js';
import { nobiCatalog } from './nobi-catalog.js';
import { akkimwaruCatalog } from './akkimwaru-catalog.js';
import { shilkyCatalog } from './shilky-catalog.js';
import { offgridCatalog } from './offgrid-catalog.js';
import { gwCatalog } from './gw-catalog.js';

export const catalogData = {
  "JAYSTATION": jaystationCatalog,
  "maatjet": maatjetCatalog,
  "Quai": quaiCatalog,
  "Nobi": nobiCatalog,
  "Akkimwaru": akkimwaruCatalog,
  "Shilky": shilkyCatalog,
  "Offgrid": offgridCatalog,
  "GARD WUZGUT": gwCatalog,
};